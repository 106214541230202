import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import TopHero from "../components/tophero"
import RepeatingIcons from "../components/repeatingicons"
import AgencyOverview from "../components/agencyoverview"
import SEO from "../components/seo"
import CustomTeaser from "../components/customteaser"
import { Helmet } from 'react-helmet'
import LinkList from '../components/linkslist'
import Banner from '../components/banner'
import Tabs from '../components/tabs'
import ContactTabs from "../components/contacttabs"
import Accordion from "../components/accordion"
import TabsSection from "../components/tabs-section"
import Slider from "../components/slider"
import LeftArrow from "../images/arrow-slider-left.svg"
import RightArrow from "../images/arrow-slider-right.svg"
import Divider from "../components/divider"
import ContactWidget from '../components/contactwidget'
import EventNewsTabs from '../components/eventnewstabs'


class PageTemplate extends Component {

  componentDidMount() {
    window.seamlessVariables = {url:"https://pwcgov.seamlessdocs.com"};
  }

  render() {
    const landingPage = this.props.data.allNodeLandingPage.edges[0].node
    var groupMenu = this.props.data.allMenuLinkContentMenuLinkContent
    const currentPage = this.props.data.nodePage
    const allAlerts = this.props.data.allNodeAlert
    const contactPage = this.props.data.allNodeContactPage
    const meta = currentPage.relationships.field_meta_data
    const relatedEvents = this.props.data.allNodeEvent
    const relatedNews = this.props.data.allNodeNews

    return (
        <Layout >
        <SEO
            title={currentPage.title}
            metaData={meta}
            contentBody={currentPage.body}
            locationOrigin={this.props.location.origin + currentPage.path.alias}
            domen={this.props.location.origin}
        />

        <div className={"site-content sub-landing_page node-"+currentPage.drupal_internal__nid}>
          {landingPage.relationships.field_hero ? (
            <TopHero landing={landingPage} menu={groupMenu} showNews={landingPage.field_show_news_in_menu} showEvents={landingPage.field_show_events_in_menu} showDocs={landingPage.field_show_forms_docs_in_menu} islanding="0" alerts={allAlerts} contactPage={contactPage} allPages={this.props.data.allSitePage} />
          ) : (
            null
          )}

          <section className="breadcrumb">
            <div className="container">
              <ul className="uk-breadcrumb breadcrumb-list">
                <li><Link to="/">Home</Link></li>
                {landingPage.relationships.field_parent_agency !== null && landingPage.relationships.field_parent_agency.relationships.field_hero.field_title_html ? (
                  <li><Link to={landingPage.relationships.field_parent_agency.path.alias}>{landingPage.relationships.field_parent_agency.relationships.field_hero.field_title_html.value}</Link></li>
                ) : (
                  null
                )}
                <li><Link to={landingPage.path.alias}>{landingPage.relationships.field_hero.field_title_html.value}</Link></li>
                <li className="uk-disabled">{currentPage.title}</li>
              </ul>
            </div>
          </section>
          <div className="content-section">
            <section className="body-content">
              <div className="container">
                <h2>{currentPage.title}</h2>
              </div>
            </section>

            {currentPage.relationships.field_basic_flexible && currentPage.relationships.field_basic_flexible.map((layout,key) =>(
              layout.__typename === 'paragraph__agency_overview' ? (
                <section className="agency-overview" key={"paragraph__agency_overview" + key}>
                  <AgencyOverview content={layout} page={currentPage} orientation="right" />
                </section>
              ) : (
                layout.__typename === 'paragraph__repeating_icon_description_link' ? (
                  <section className="login-find-activity-park" key={"paragraph__repeating_icon_description_link" + key}>
                    <RepeatingIcons content={layout} />
                  </section>
                ) : (
                  layout.__typename === 'paragraph__overview_extended' ? (
                    <section className="agency-overview" key={"paragraph__overview_extended" + key}>
                      <AgencyOverview content={layout} page={currentPage} orientation="left" />
                    </section>
                  ) : (
                    layout.__typename === "paragraph__custom_teaser" ? (
                      <section className="teaser" key={"paragraph__custom_teaser" + key}>
                        <CustomTeaser content={layout} />
                      </section>
                    ) : (
                      layout.__typename === 'paragraph__wysiwyg' ? (
                        <section className={"paragraph__wysiwyg" + key}>
                          <div className="container">
                            <div dangerouslySetInnerHTML={{ __html: layout.field_description_wysiwyg.processed }} />
                          </div>
                        </section>
                      ) : (
                        layout.__typename === "paragraph__repeating_title_and_repeating_li" ? (
                          <section className="links-list" key={"paragraph__repeating_title_and_repeating_li" + key}>
                            <div className="container">
                              <LinkList content={layout} />
                            </div>
                          </section>
                          ) : (
                            layout.__typename === "paragraph__banner" ? (
                              <section className="banner" key={"paragraph__banner" + key}>
                                <div className="container">
                                  <Banner content={layout} />
                                </div>
                              </section>
                            ) : (
                              layout.__typename === "paragraph__tabs_custom_teasers" ? (
                                <section className="landing-tabs" key={"paragraph__tabs_custom_teasers" + key}>
                                  <div className="container">
                                    <Tabs content={layout} />
                                  </div>
                                </section>
                              ) : (
                                layout.__typename === "paragraph__tabs_with_contact_teasers" ? (
                                <section className="info-tabs pools" key={"paragraph__tabs_with_contact_teasers" + key}>
                                  <ContactTabs tabs={layout.relationships.field_tab_with_contact_teasers} />
                                </section>
                              ) : (
                                layout.__typename === 'paragraph__accordion' ? (
                                  <section className="accordion-section" key={"paragraph__accordion"+key}>
                                    <Accordion content={layout} />
                                  </section>
                                ) : (
                                  layout.__typename ==='paragraph__slider' ? (
                                    <section className="slider-section" key={"paragraph__slider"+key}>
                                      <Slider content={layout} />
                                    </section>
                                  ) : (
                                    layout.__typename ==='paragraph__tabs' ? (
                                      <section className="tabs-section" key={"paragraph__tabs"+key}>
                                        <TabsSection content={layout} />
                                      </section>
                                    ) : (
                                      layout.__typename ==='paragraph__horizontal_divider' ? (
                                        <section className="divider" key={"paragraph__horizontal_divider"+key}>
                                          <Divider />
                                        </section>
                                      ) : (
                                        layout.__typename ==='paragraph__contact_widget' ? (
                                        <section className="contact-widget" key={"paragraph__contact_widget"+key}>
                                          <ContactWidget content={layout}/>
                                        </section>
                                        ) : (
                                          layout.__typename === 'paragraph__news_events_social_placeholder' ? (
                                          <section className="media-section" key={"paragraph__news_events_social_placeholder" + key}>
                                            <EventNewsTabs relatedNews={relatedNews} filterEvents={relatedEvents.edges} currentPage={landingPage} />
                                          </section>
                                          ) : (
                                            null
                                          )
                                        )
                                      )
                                    )
                                  )
                                )
                              )
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            ))}

            {currentPage.body && currentPage.body.processed ? (
              <section className="body-content">
                <div className="container">
                  <div dangerouslySetInnerHTML={{ __html: currentPage.body.processed }} />
                </div>
              </section>
            ) : (
              null
            )}

          </div>

          {currentPage.field_description_wysiwyg !== null ? (
            <section>
              <Helmet>
                <script async type="text/javascript" src="https://pwcgov.seamlessdocs.com/components/odoForms/js/embed-iframe/head.js"></script>
                <script async type="text/javascript" src="https://pwcgov.seamlessdocs.com/components/odoForms/js/embed-iframe/body.js"></script>
              </Helmet>
              <div className="container">
                <div dangerouslySetInnerHTML={{ __html: currentPage.field_description_wysiwyg.value}}/>
              </div>
            </section>
          ) : (
            null
          )}

        </div>
        </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!, $groupmenu: String, $landing: Int, $groupid: Int, $date: Date) {

    allNodeEvent(limit: 6,filter: {field_start_date: {gt: $date} ,relationships: {group_content__departments_group_node_event: {elemMatch: {relationships: {gid: {drupal_internal__id: {eq: $groupid}}}}}}}, sort: {fields: field_start_date, order: ASC}) {
      edges {
        node {
          field_start_date
          field_end_date
          field_address_line_1
          field_address_line_2
          title
          path {
            alias
          }
          title
          relationships {
            group_content__departments_group_node_event {
              label
              relationships {
                gid {
                  label
                  drupal_internal__id
                }
              }
            }
          }
        }
      }
    }


    nodePage(id: { eq: $id }) {
      title
      path {
        alias
      }
      field_description_wysiwyg {
        value
      }
      relationships {
        field_meta_data {
          ...paragraph__meta_dataFragment
        }
        field_basic_flexible {
          ... on paragraph__custom_teaser {
            field_title
            field_description
            field_link {
              title
              uri
            }
            relationships {
              field_media_image {
                relationships {
                  field_media_image {
                    uri {
                      url
                    }
                  }
                }
              }
            }
          }

          ... on paragraph__news_events_social_placeholder {
            field_placeholder_info
          }

          ... on paragraph__tabs {
            id
            relationships {
              field_tab_item {
                field_title
                field_description_wysiwyg {
                  processed
                }
              }
            }
          }

          ... on paragraph__slider {
            relationships {
              field_slider_item {
                ... on paragraph__slider_image {
                  field_description
                  relationships {
                    field_media_image {
                      relationships {
                        field_media_image {
                          uri {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                ... on paragraph__slider_video {
                  field_title
                  field_youtube_or_vimeo
                }
              }
            }
          }

          ... on paragraph__accordion {
            field_half_width
            field_title
            field_animate_items
            relationships {
              field_accordion_item {
                field_title
                field_description_wysiwyg {
                  processed
                }
              }
            }

          }

          ... on paragraph__tabs_with_contact_teasers {
            id
            relationships {
              field_tab_with_contact_teasers {
                field_title
                field_description_wysiwyg {
                  value
                  processed
                }
                relationships {
                  field_pool_info {
                    field_title
                    field_description_wysiwyg {
                      value
                      processed
                    }
                    relationships {
                      field_media_image {
                        relationships {
                          field_media_image {
                            uri {
                              url
                            }
                          }
                        }
                      }
                      field_contact_info {
                        ... on paragraph__contact_address {
                          field_address
                          field_address_line_2
                        }
                        ... on paragraph__contact_custom_text {
                          field_description_wysiwyg {
                            value
                            processed
                          }
                        }
                        ... on paragraph__contact_phone {
                          field_phone
                          field_title
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          ... on paragraph__tabs_custom_teasers {
            id
            field_rotation_interval
            relationships {
              field_tab_teaser {
                field_title
                field_tab_title
                field_link {
                  title
                  uri
                }
                field_link_2 {
                  title
                  uri
                }
                field_video_link {
                  title
                  uri
                }
                field_description
                relationships {
                  field_media_image {
                    relationships {
                      field_media_image {
                        uri {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          ... on paragraph__wysiwyg {
            field_description_wysiwyg {
              value
              processed
            }
          }

          ... on paragraph__banner {
            field_description
            field_title
            field_link {
              uri
              title
            }
            relationships {
              field_media_image {
                relationships {
                  field_media_image {
                    uri {
                      url
                    }
                  }
                }
              }
            }
          }

          ... on paragraph__horizontal_divider {
            id
          }

          ... on paragraph__contact_widget {
            field_title
            relationships {
              field_contact_widget {
                ... on paragraph__contact_address {
                  field_address
                  field_address_line_2
                }
                ... on paragraph__contact_email {
                  field_email
                }
                ... on paragraph__contact_mobile_phone {
                  field_phone
                }
                ... on paragraph__contact_phone {
                  field_title
                  field_phone
                }
                ... on paragraph__contact_link {
                  field_contact_link {
                    title
                    uri
                  }
                }
              }
            }
          }

          ... on paragraph__repeating_icon_description_link {
            id
            relationships {
              field_item {
                field_link {
                  title
                  uri
                }
                field_description
                field_title
                relationships {
                  field_icon {
                    uri {
                      url
                    }
                  }
                }
              }
            }
          }
          ... on paragraph__repeating_title_and_repeating_li {
            relationships {
              field_links_section {
                field_title
                field_repeating_link {
                  title
                  uri
                }
              }
            }
          }
          ... on paragraph__agency_overview {
            field_title
            field_description
            field_name
            field_position
            field_link {
              title
              uri
            }
            relationships {
              field_contact {
                ... on paragraph__contact_address {
                  field_address
                  field_address_line_2
                }
                ... on paragraph__contact_email {
                  field_email
                }
                ... on paragraph__contact_phone {
                  field_phone
                  field_title
                }
                ... on paragraph__contact_custom_text {
                  field_description_wysiwyg {
                    processed
                  }
                }
                ... on paragraph__contact_fax {
                  field_phone
                }
                ... on paragraph__contact_mobile_phone {
                  field_phone
                }
              }
              field_media_image {
                relationships {
                  field_media_image {
                    uri {
                      url
                    }
                  }
                }
              }
            }
          }
          ... on paragraph__overview_extended {
            field_title
            field_description_wysiwyg {
              value
              processed
            }
            field_name
            field_position
            field_link {
              title
              uri
            }
            relationships {
              field_contact {
                ... on paragraph__contact_address {
                  field_address
                  field_address_line_2
                }
                ... on paragraph__contact_email {
                  field_email
                }
                ... on paragraph__contact_phone {
                  field_phone
                  field_title
                }
              }
              field_media_image {
                relationships {
                  field_media_image {
                    uri {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
      drupal_internal__nid
      body {
        value
        processed
      }
    }

    allNodeNews(sort: {fields: created, order: DESC}, limit: 9, filter: {relationships: {group_content__departments_group_node_news: {elemMatch: {relationships: {gid: {drupal_internal__id: {eq: $groupid}}}}}}}) {
      edges {
        node {
          path {
            alias
          }
          title
          relationships {
            field_media_image {
              relationships {
                field_media_image {
                  uri {
                    url
                  }
                }
              }
            }
            group_content__departments_group_node_news {
              label
              relationships {
                gid {
                  label
                  drupal_internal__id
                }
              }
            }
          }
        }
      }
    }

    allSitePage(filter: {context: {drupal_nid: {ne: null}}}) {
      ...SitePageConnectionFragment
    }

    allNodeAlert(sort: {fields: field_weight, order: ASC}) {
      edges {
        ...node__alertEdgeFragment
      }
    }

    allNodeContactPage(filter: {relationships: {group_content__group_content_type_4f1d2aba752bd: {elemMatch: {relationships: {gid: {drupal_internal__id: {eq: $groupid}}}}}}}) {
      edges {
        node {
          title
          path {
            alias
          }
        }
      }
    }

    allMenuLinkContentMenuLinkContent(filter: {menu_name: {eq: $groupmenu}, enabled: {eq: true}}, sort: {fields: weight, order: ASC}) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          title
          link {
            title
            uri
          }
        }
      }
    }
    allNodeLandingPage(filter: {drupal_internal__nid: {eq: $landing}}) {
      edges {
        ...node__landing_pageEdgeFragment
      }
    }
  }
`
